import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function createParcel(body: any) {
  const { name } = body;
  const data = {
    name,
  };
  const response = await axios.post(
    `${API_URL}catalogos/delivery-agencies/`,
    data
  );
  return response;
}
