import { useState, useEffect } from 'react';
import { getAllCuentas } from '../../../service/operacion/Cuentas';
import { CuentaModel } from '../models/CuentaModel';
import { useHistory } from 'react-router-dom';
import {
  getSelectDelivery,
  getCatalogSelect,
} from '../../../service/envios/envios';
import { SelectFormat } from '../models/CuentaModel';

export const useGetAll = (realoadGrid: number, filters: any) => {
  const [data, setData] = useState<any[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getAllCuentas(filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realoadGrid, filters]);
  return { data, loadingRequest };
};

export const useGetContactSupplier = (realoadGrid: number) => {
  const [dataContactos, setDataContactos] = useState<any[]>([]);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getSelectDelivery()
        .then()
        .catch((error: any) => {
          history.push('/error/500');
        });
      if (res) {
        let contacts = res;
        const selectOptions = contacts.map((option: any) => ({
          value: option.id,
          label: option?.name,
        }));
        setDataContactos(selectOptions);
      }
    };

    fetchPost();
  }, [realoadGrid]);

  return { dataContactos };
};

export const useSelectGenericPlus = (reload?: number) => {
  const [catalog, setCatalog] = useState<SelectFormat[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getCatalogSelect()
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (data) {
        setCatalog(data);
        setLoadingRequest(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return { catalog, loadingRequest };
};
